import React from 'react';
import { graphql } from 'gatsby';
import HomeImage from '../components/HomeImage/HomeImage';
import Meta from '../components/Meta/Meta';

export default function Home({ data: { sanityHomeImage } }) {
  const homeImageFile = sanityHomeImage.image;

  return (
    <>
      <Meta title="Ottone Photography" />
      <HomeImage homeImageFile={homeImageFile} />
    </>
  );
}

export const query = graphql`
  query {
    sanityHomeImage {
      image {
        alt
        image {
          asset {
            fluid(maxWidth: 2000) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
