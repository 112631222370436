import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import * as style from './HomeImage.module.scss';

export default function HomeImage({ homeImageFile }) {
  const image = homeImageFile.image.asset.fluid;
  const imageAlt = homeImageFile.alt;

  const [windowHeight, setWindowHeight] = useState(0);

  const getWindowHeight = () => {
    if (typeof window === 'undefined') return;
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    getWindowHeight();
    window.addEventListener('resize', getWindowHeight);
    return () => window.removeEventListener('resize', getWindowHeight);
  }, []);

  return (
    <div
      style={{ height: `calc(${windowHeight}px - (2 * var(--padding)) - var(--headerHeight) - var(--footerHeight))` }}
      className={style.imageContainer}
    >
      <Img style={{ height: '100%' }} fluid={image} alt={imageAlt} />
    </div>
  );
}
